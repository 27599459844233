export const segmentOne = 'The countryside navigator';
export const segmentTwo = 'The meticulous driver';
export const segmentThree = 'The adventurous driver';
export const segmentFour = 'Edgy suburban biker';
export const segmentFive = 'The urban explorer';

export const recommendedOneSegments = [segmentOne, segmentThree, segmentFive];
export const recommendedTwoSegments = [segmentTwo, segmentFour];

export const VIP = 'VIP';
export const vipGold = 'Gold';
export const vipSilver = 'Silver';
export const vipBronze = 'Bronze';

export const gigyaLoginScreen = 'gigya-login-screen';
export const gigyaRegisterScreen = 'gigya-register-screen';
// export const gigyaRegisterScreen = 'gigya-register-screen';

export const gigyaOrgRegisterScreen = 'gigya-org-register-screen';

export const gigyaRegisterCompletionScreen = 'gigya-complete-registration-screen';
export const gigyaProgProfilingScreen = 'gigya-prog-profiling';

export const enum responseStatus {
    OK = 'OK'
}

export const gigyaDefaultRegisterScreen = 'Default-RegistrationLoginCustom';

