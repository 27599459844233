import React, {useEffect} from 'react';
import './ButtonList.css';
import {useDispatch, useSelector} from "react-redux";
import {setActiveButton} from "../../Features/OrderManagement/orderManagementSlice";

export const buttons = ["Select Model", "Color", "Services", "Accessories"];

export const ButtonList = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveButton(buttons[0]));
    }, [dispatch]);

    const activeButton = useSelector(state => state.orderManagement.activeButton);

    return (
        <div className="parentContainer">
            <ul className="buttonList">
                {buttons.map((label) => (
                    <li
                        key={label}
                        className={`buttonItem ${activeButton === label ? 'active' : ''}`}
                        onClick={() => dispatch(setActiveButton(label))}
                    >
                        <div className="textWrapper">{label}</div>
                    </li>
                ))}
            </ul>
        </div>

    );
}

export default ButtonList;
