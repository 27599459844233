import deleteImage from '../Images/delete.png';


const MyOrders = ({ orderId, closeMyOrder, vehicleID }) => {
    const getCurrentDate = () => {
        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = now.toLocaleString('default', { month: 'short' });
        var next_month_string = (day) + " " + (month) + ' ' + now.getFullYear();
        return next_month_string;
    }
    const orderDetails = [
        {
            name: 'Order Confirmed',
            date: getCurrentDate(),
            isActive: true
        },
        {
            name: 'Vehicle assigned',
            isActive: vehicleID ? true : false
        },
        {
            name: 'Dispached from factory',
            isActive: false
        },
        {
            name: 'Received by Dealer',
            isActive: false
        },
        {
            name: 'Ready for Delivery',
            isActive: false
        }
    ]
    const isActive = orderDetails.find(detail => detail.isActive);
    const getEstimatedDate = () => {
        var now = new Date();
        var next_month = new Date(now.setMonth(now.getMonth() + 1));

        // Manual date formatting
        var day = ("0" + next_month.getDate()).slice(-2);
        var month = next_month.toLocaleString('default', { month: 'short' });
        var next_month_string = (day) + " " + (month) + ' ' + next_month.getFullYear();
        return next_month_string;
    }

    const orderList = [
        {
            name: 'Order ID',
            value: orderId
        },
        {
            name: 'Model',
            value: 'SAP E1'
        },
        {
            name: 'Varient',
            value: 'Electric'
        },
        {
            name: 'Requested Delivery',
            value: getEstimatedDate()
        },
        {
            name: 'Order Status',
            value: 'Confirmed'
        },
        {
            name: 'Vehicle Status',
            value: vehicleID ? 'Assigned' : 'Unassigned'
        },
        {
            name: 'Order ON',
            value: getCurrentDate()
        }
    ]
    return (
        <div className='form myorder-container-child'>
            <div className="myorder-container">
                {
                    orderList.map((list) =>
                        <div style={{ padding: '0 24px 0 24px' }}>
                            <h5>{list.name}</h5>
                            <span>{list.value}</span>
                        </div>
                    )
                }
                <div style={{ padding: '0 24px 0 24px' }}>
                    <div className='delete-text'>Delete</div>
                    <img style={isActive ? { opacity: '0.5' } : null} className='delete-img' src={deleteImage} />
                </div>
            </div>
            <div className="myorder-container" style={{ gap: '5%' }}>
                {
                    orderDetails.map((detail) =>
                        <div className="flex-container" style={detail.isActive ? { background: '#16aa51', color: '#fff' } : { background: '#dbdfe5' }}>
                            <div className="column">{detail.name}</div>
                            {detail?.date ? <div className="bg-alt">{detail.date}</div> : null}
                        </div>
                    )
                }
            </div>
            <div className="close-button-container">
                <button type="submit" class="btn-style2" onClick={() => closeMyOrder()}>Close</button>
            </div>
        </div>

    )
}
export default MyOrders;
