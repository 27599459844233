import React from "react";

export const ColorList = ({ colorList, activeClasses, toggleClass }) => (
    <div className='colour-np'>
        <div className='colour-container-np' style={{ display: 'flex', flexWrap: 'wrap', marginTop: '40px' }}>
            {colorList.map(item => (
                <div
                    key={item.id}
                    className={activeClasses[item.id] ? "each-colour-active-border-np" : 'each-colour-non-active-border-np'}
                    onClick={() => toggleClass(item.id)}>
                    <img className="each-colour-active-np" src={item.url} />
                </div>
            ))}
        </div>
    </div>
);