const gigya = window.gigya;

export const getUserSegments = () => {
    const url = new URL('https://dvs-api.gigya-cs.com/api/userActions/getUserSegments');
    // const url = new URL('http://localhost:5000/api/userActions/getUserSegments');
    url.searchParams.append('email', window.email);

    return fetch(url)
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => console.error('Error:', error));
}

export const getUserFromCDC = () => {
    return new Promise((resolve, reject) => {
        gigya.accounts.getAccountInfo({ callback: (response) => {
            if (response.errorCode === 0) {
                const user = {
                    UID: response.UID,
                    email: response.profile.email,
                    firstName: response.profile.firstName,
                    lastName: response.profile.lastName,
                    financing: response.data.financing,
                    tradeIn: response.data.trading
                };
                resolve(user);
            }
            else {
                reject(response.errorCode);
            }
        }});
    })
}
