import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isRecommendedOne: false,
    isRecommendedTwo: false,
}

const recommendedSlice = createSlice({
    name: 'recommended',
    initialState,
    reducers: {
        setRecommendedOne: (state, action) => {
            state.isRecommendedOne = action.payload;
        },
        setRecommendedTwo: (state, action) => {
            state.isRecommendedTwo = action.payload;
        }
    },
});

export default recommendedSlice.reducer;
export const {setRecommendedOne, setRecommendedTwo} = recommendedSlice.actions;