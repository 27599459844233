/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import SliderContent from './SliderContent'
import Slide from './Slide'
import Arrow from './Arrow'
import Dots from './Dots'


/**
 * @function Slider
 */
const Slider = ({slides,model}) => {
  const getWidth = () => window.innerWidth
  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45
  })

  const { translate, transition, activeIndex } = state

  const nextSlide = () => {
    if (activeIndex === slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0
      })
    }

    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth()
    })
  }

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (slides.length - 1) * getWidth(),
        activeIndex: slides.length - 1
      })
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * getWidth()
    })
  }

  return (
    <div css={SliderCSS} style={{backdropFilter: 'blur(0px) !important'}}>
      <div css={CustomNowCSS}>CUSTOM<br/>NOW.</div>
      <SliderContent
        translate={translate}
        transition={transition}
        width={getWidth() * slides.length}
      >
        {slides.map((slide, i) => (
          <Slide key={slide + i} content={slide} />
        ))}
      </SliderContent>

      {/*<Arrow direction="left" handleClick={prevSlide} />*/}
      {/*<Arrow direction="right" handleClick={nextSlide} />*/}
      <div className='three-attributes-container' css={ThreeAttributesContainerCSS}>
        <div>
          <p className='val-att-np'>{model.speed} km/h</p>
          <p className='name-att-np'>Top Speed</p>
        </div>
        <div>
          <p className='val-att-np'>{model.time} Sec</p>
          <p className='name-att-np'>0 to 40 km/h</p>
        </div>
        <div>
          <p className='val-att-np'>{model.range} km</p>
          <p className='name-att-np'>True Range</p>
        </div>
      </div>
      <Dots slides={slides} activeIndex={activeIndex} />
    </div>
  )
}

const SliderCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  width: calc(100% - 400px);
  overflow: hidden;
  backdrop-filter: blur(0px) !important;
  .three-attributes-container p {
    color: #cbced7;
  }
`

const ThreeAttributesContainerCSS = css`
  position: absolute;
  bottom: 7%; // adjust this value as per your requirements
  display: flex;
  justify-content: center;
  width: 80%; // adjust as necessary to space out the attributes
  > div {
    width: 30%; // adjust this as necessary
    text-align: center; // to ensure the text within each div is centered
  }
`

const CustomNowCSS = css`
  position: absolute;
  top: 15vh;
  left: 8vh;
  font-weight: 750;
  font-size: 48px;
  color: white;
  letter-spacing: 6px; // Adjust as needed to get the desired spacing
  
  &::after {
    content: "Create Your Own SAPCycle!";
    display: block; // to position it below the main content
    font-weight: 20; // to make it non-bold
    font-size: 14px; // you can adjust this size as needed
    letter-spacing: normal; // reset letter spacing
    margin-top: 5px; // spacing between the main text and this
    color: lightgrey;
  }
  
`;



export default Slider