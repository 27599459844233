import React, {useEffect} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import './App.css';
import Home from './Home'
import LandingPage from './Orders/LandingPage';

function App() {

  useEffect(() => {
    document.title = "MotoSAP";
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/loggedIn" element={<LandingPage/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
