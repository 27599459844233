import blue from "../Images/blue.png";
import green from "../Images/green.png";
import orange from "../Images/orange.png";
import purple from "../Images/purple.png";
import grey from "../Images/grey.png";
import asphalt from "../Images/asphalt.png";
import red from "../Images/red.png";

export const colorList = [
    {
        id: 0,
        url: blue,
        color: 'Blue'
    },
    {
        id: 1,
        url: green,
        color: 'Green'
    },
    {
        id: 2,
        url: orange,
        color: 'Orange'
    },
    {
        id: 3,
        url: purple,
        color: 'Purple'
    },
    {
        id: 4,
        url: grey,
        color: 'Grey'
    },
    {
        id: 5,
        url: asphalt,
        color: 'Asphalt'
    },
    {
        id: 6,
        url: red,
        color: 'Red'
    },
]
