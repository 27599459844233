import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Location = ({ closeTestRide }) => {
    const [isSuccessPopup, setIsSuccessPopup] = useState(false);
    const servicLocations = [
        {
            name: 'Indiranagar',
            address: '53, 100th Feet Rd, Defence Colony, Indiranagar, Bengaluru, Karnataka 560038'
        },
        {
            name: 'JP nagar',
            address: '292, 15th cross, JP Nagar 5th phase(opp HP PETROL PUMP) Bangalore 560078'
        },
        {
            name: 'Yelehanka',
            address: 'No.796, MIG 2nd Stage,Mother Diary Road, Yelahanka New Town Yelahanka, Bangalore, Karnataka - 560064.'
        },

    ]
    const handleLocation = (e) => {
        [...e.currentTarget.parentNode.childNodes].map(child => {
            child.classList.remove('active');
        })
        if (e.currentTarget.classList.contains('active')) {
            e.currentTarget.classList.remove('active');
        } else {
            e.currentTarget.classList.add('active');
        }
    }
    const handleConfirm = () => {
        const getChild = [...document.getElementsByClassName('location-list')[0].childNodes].find(child => child.classList.contains('active'))
        if (getChild) {
            setIsSuccessPopup(true);
        }
    }
    return (
        <div className='form myorder-container-child1'>
            <div className="service-location-container-child">
                <button style={{ width: 'fit-content' }} type="submit" onClick={() => closeTestRide()} class="btn-style1" >back</button>
            </div>
            {isSuccessPopup ? <Stack sx={{ zIndex: '1000', position: 'absolute' }} spacing={2}>
                <Alert onClose={() => setIsSuccessPopup(false)} severity="success">Test Ride is Confirmed</Alert>
            </Stack> : null}
            <div className="location-list">
                {servicLocations.map(location => {
                    return (
                        <div className="service-location-container" onClick={handleLocation}>
                            <div className="service-location-container-child">
                                <span className="location-name">{location.name}</span>
                                <span style={{ fontSize: '10px', lineHeight: '24px' }}>10:00 AM - 6:00 PM</span>
                            </div>
                            <div>
                                <span className="addr">{location.address}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div style={{ display: 'flex', gap: '5%', justifyContent: 'flex-end' }}>
                <input style={{ width: 'fit-content',height: '50%' }} type='date'></input>
                <input style={{ width: 'fit-content',height: '50%' }} type='time'></input>
                <button style={{ width: 'fit-content',height: '50%' }} type="submit" onClick={handleConfirm} class="btn-style1" >Confirm</button>
            </div>
        </div>
    )
}
export default Location