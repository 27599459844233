
const TestRide = ({ closeTestRide, openLocation }) => {


    const locations = [
        {
            name: 'Banglore',
            isActive: true
        },
        {
            name: 'Hyderabad',
            isActive: false
        },
        {
            name: 'Delhi',
            isActive: false
        },
        {
            name: 'Ncr',
            isActive: false
        },
        {
            name: 'Chennai',
            isActive: false
        }
    ]

    return (
        <div className='form myorder-container-child'>
            <button style={{ width: 'fit-content' }} type="submit" onClick={() => closeTestRide()} class="btn-style1" >back</button>
            <div className="test-container">
                <h1>Book a test ride</h1>
                <div className="location-container">
                    {
                        locations.map(location => {
                            return (
                                <div className={`location ${location.isActive ? '' : 'disabledbutton'}`} onClick={() => openLocation()}>
                                    <div className="location-name">{location.name}</div>
                                    <span>|</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

    )
}
export default TestRide;
