export const models = [
    {
        id: 0,
        name: 'SAP E1',
        range: '128',
        speed: '95',
        price: '$100,000.00',
        time: '3.8',
        stock: 'Only 2 in stock',
        stockColor: '#B95100',
        textSize: '0.825em'
    }, {
        id: 1,
        name: 'SAP E1 Pro',
        range: '170',
        speed: '117',
        price: '$132,999.00',
        time: '2.9',
        stock: 'Out of stock',
        stockColor: '#AA0808',
        textSize: '0.825em'
    }
]
