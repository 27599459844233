import {useEffect, useRef, useState} from "react";

export const useOrderState = () => {
    const [index, setIndex] = useState(0);
    const [modelIndex, setModelIndex] = useState(0);
    const modelIndexRef = useRef(modelIndex);

    useEffect(() => {
        modelIndexRef.current = modelIndex;
    }, [modelIndex]);

    return {
        index,
        setIndex,
        modelIndex,
        setModelIndex,
        modelIndexRef,
    };
};


