import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {resetUserDetails, setIsBusiness, setName} from "../Features/UserDetails/userDetailsSlice";


const Logout = ({orderId}) => {
    const navigate = useNavigate();
    const gigya = window.gigya;
    const dispatch = useDispatch();

    const vipStatus = useSelector(state => state.userDetails.vip);
    const isBusiness = useSelector(state => state.userDetails.isBusiness);

    const handleLogout = () =>{
        dispatch(resetUserDetails());

        gigya.accounts.logout();
        navigate("/");
    }
    const viewProfile = () =>{
        gigya.accounts.showScreenSet({
                screenSet: 'Default-ProfileUpdate'
        });
    }
    return (
        <div className='form1'>
            <div className="form-auth1">
                <div className="form-body">
                <div style={{ marginTop: '10px' ,fontWeight:'500'}}>
                    {isBusiness ? <span>Organization: </span> : null } <label>{window.fullName}</label>
                </div>
                {vipStatus ? <div style={{fontSize: 14, fontWeight: '500'}}>VIP:
                    <span style={{color: '#ffd700'}}> {vipStatus} </span>
                </div> : null}
                <div style={{ marginTop: '10px' }}>
                    <div class="input-box-container"
                    >
                        <button type="submit" class="btn" onClick={viewProfile} >View Profile</button>
                    </div>
                </div>
                <div style={{ marginTop: '10px' }}>
                    <div class="input-box-container"
                    >
                        <button type="submit" class="btn" onClick={handleLogout} >Logout</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Logout