// Utils.js
import {recommendedOneSegments, recommendedTwoSegments, VIP} from './Constants.ts';
import {getUserSegments} from "../Services/apiService";
import {setRecommendedOne, setRecommendedTwo} from "../Features/RecommendedMark/recommendedSlice";
import {setVIP} from "../Features/UserDetails/userDetailsSlice";

export const updateBySegments = async (dispatch, setShouldNavigate) => {
    try {
        const data = await getUserSegments();

        dispatch(setRecommendedOne(false));
        dispatch(setRecommendedTwo(false));

        if (data === 'no segments' || data === undefined) { // getting from server if there are no segments.
            setShouldNavigate(true);
            return;
        }

        if (data.some(item => recommendedOneSegments.includes(item.name))) {
            dispatch(setRecommendedOne(true));
        }
        else if (data.some(item => recommendedTwoSegments.includes(item.name))) {
            dispatch(setRecommendedTwo(true));
        }

        const vipSegment = data.find(item => VIP === item.name);
        if (vipSegment) {
            dispatch(setVIP(vipSegment.value));
        }

        setShouldNavigate(true);
    }
    catch (error) {
        console.log(error);
    }
};

export const delay = (duration) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });
}

export const getEstimatedDate = () => {
    var now = new Date();
    var next_month = new Date(now.setMonth(now.getMonth() + 1));
    // if (index === 1 && modelIndex === 0) {
    //     next_month = new Date(now.setDate(now.getDate() + 5))
    // } else {
    //     next_month = new Date(now.setMonth(now.getMonth() + 1));
    // }

    // Manual date formatting
    var day = ("0" + next_month.getDate()).slice(-2);
    var month = next_month.toLocaleString('default', { month: 'long' });
    var next_month_string = (day) + " " + (month) + ' ' + next_month.getFullYear();
    return next_month_string;
}
