import React, { useState } from 'react';
import '../App.css'

const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
      <div className='loading-text'>
      <h4>InProgress</h4>
      </div>
    </div>
  );
}
export default LoadingSpinner;